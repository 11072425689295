<div class="flex-container">
  <div class="flex-item">
    <p>Commission amounts have been approved for payment.</p>
    <button
          class="home-page-button"
          style="margin-bottom: 1rem"
          mat-flat-button
          color="primary"
          routerLink="/"
        >
          BACK TO HOME PAGE
        </button>
  </div>
</div>



