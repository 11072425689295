<h3>{{ title }}</h3>
<table *ngIf="images.length > 0">
  <tr>
    <th>Image URL</th>
    <th>Alt Text</th>
    <th *ngIf="enableMannequinImage">Mannequin Image?</th>
    <th>Delete</th>
  </tr>
  <tr *ngFor="let image of images; index as i">
    <td>{{ image.src }}</td>
    <td>{{ image.alt }}</td>
    <td *ngIf="enableMannequinImage">{{ image.mannequinImage }}</td>
    <td>
      <button mat-raised-button type="button" (click)="onDelete(i)">
        Delete
      </button>
    </td>
  </tr>
</table>
<form [formGroup]="form" #formDirective="ngForm" (ngSubmit)="onSubmit()">
  <mat-form-field appearance="outline" hideRequiredMarker>
    <mat-label>Image URL</mat-label>
    <input matInput formControlName="src" type="url" />
  </mat-form-field>
  <mat-form-field appearance="outline" hideRequiredMarker>
    <mat-label>Image Alt Text</mat-label>
    <input matInput formControlName="alt" />
  </mat-form-field>
  <ng-container *ngIf="enableMannequinImage">
    <mat-form-field appearance="outline" hideRequiredMarker>
      <mat-label>Mannequin Image</mat-label>
      <select matNativeControl formControlName="mannequin">
        <option label="--"></option>
        <option *ngFor="let symbol of keys(MannequinImageTypeEnum)" [ngValue]="symbol">{{symbol}}</option>
        <option *ngFor="let symbol of keys(MannequinAccessoryImageTypeEnum)" [ngValue]="symbol">{{symbol}}</option>
      </select>
    </mat-form-field>
  </ng-container>
  <button mat-raised-button>Add Image</button>
</form>
