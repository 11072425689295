import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { AccountMaster, DocumentResults } from 'common-types';
import { BehaviorSubject, Observable, Subscription, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AccountService implements OnDestroy {
  private readonly apiUrl = this.environment.account_rest_api_server_url;
  private readonly reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  private subscription = new Subscription();
  private refreshAccounts$ = new BehaviorSubject<null>(null);
  public accounts$: Observable<DocumentResults<AccountMaster>>;

  constructor(
    @Inject('environment') private environment: any,
    private httpClient: HttpClient,
  ) {
    this.accounts$ = this.refreshAccounts$.pipe(
      switchMap(() => this.apiGetAccountPaginated(3, '0', '0')),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public apiGetAllAccounts(): Observable<Array<AccountMaster>> {
    return this.httpClient.get<Array<AccountMaster>>(this.apiUrl, {
      headers: this.reqHeader,
    });
  }

  public apiGetAccountsPaginatedPromise(
    pageSize: number,
    startBeforeId: string | null = null,
    startAfterId: string | null = null,
  ): Promise<DocumentResults<AccountMaster>> {
    return new Promise((resolve, reject) => {
      let url: string =
        this.apiUrl +
        '/pageSize/' +
        pageSize +
        '/startBeforeId/' +
        startBeforeId +
        '/startAfterId/' +
        startAfterId;

      this.subscription.add(
        this.httpClient
          .get<DocumentResults<AccountMaster>>(url, {
            headers: this.reqHeader,
          })
          .subscribe((data) => {
            resolve(data);
          }),
      );
    });
  }

  public apiGetAccountPaginated(
    pageSize: number,
    startBeforeId: string | null = null,
    startAfterId: string | null = null,
  ): Observable<DocumentResults<AccountMaster>> {
    let url: string =
      this.apiUrl +
      '/pageSize/' +
      pageSize +
      '/startBeforeId/' +
      startBeforeId +
      '/startAfterId/' +
      startAfterId;

    return this.httpClient.get<DocumentResults<AccountMaster>>(url, {
      headers: this.reqHeader,
    });
  }

  public apiGetAccountByAccountNumber(
    accountNumber: string,
  ): Observable<AccountMaster> {
    return this.httpClient.get<AccountMaster>(
      `${this.apiUrl}/accountNumber/${accountNumber}`,
      {
        headers: this.reqHeader,
      },
    );
  }

  public apiCreateAccount(account: AccountMaster): Observable<AccountMaster> {
    return this.httpClient.post<AccountMaster>(
      this.apiUrl,
      JSON.stringify(account),
      {
        headers: this.reqHeader,
      },
    );
  }
}
