<mat-spinner *ngIf="showSpinner"></mat-spinner>

<div *ngIf="account !== undefined">
  <div class="account-head">
    <div style="flex: 0 0 300px">
      <h2>{{ account.accountName }}</h2>
      <h3>{{ account.accountNumber }}</h3>
    </div>
    <div style="flex: 1 1 80%">
      <button mat-stroked-button routerLink="/account/manage">
        Back to Account List
      </button>
    </div>
  </div>

  <div class="property">
    <div class="property-label">Company:</div>
    <div class="property-value">{{ account.company }}</div>
  </div>

  <div class="property">
    <div class="property-label">Service Center:</div>
    <div class="property-value">{{ account.serviceCenter }}</div>
  </div>

  <div class="property">
    <div class="property-label">Phone:</div>
    <div class="property-value">{{ account.phone }}</div>
  </div>

  <div class="property">
    <div class="property-label">Email:</div>
    <div class="property-value">{{ account.email }}</div>
  </div>

  <div class="property">
    <div class="property-label">Web Site:</div>
    <div class="property-value">{{ account.accountWebSite }}</div>
  </div>

  <div class="property">
    <div class="property-label">Default Carrier:</div>
    <div class="property-value">{{ account.defaultCarrier }}</div>
  </div>
</div>
