import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { PortalFeatureModuleGroup } from 'common-types';
import { FeatureModuleService } from '../../services/feature-module/feature-module.service';
import { Router, Routes } from '@angular/router';
import { PortalUserService } from '../../services/portal-user/portal-user.service';
import { PlatformUser } from 'business-logic';

@Component({
  selector: 'jfw-portal-home',
  templateUrl: './portal-home.component.html',
  styleUrls: ['./portal-home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortalHomeComponent implements OnInit {
  featureModuleGroups: PortalFeatureModuleGroup[] = [];
  routes: Routes | undefined;
  portalUser: PlatformUser | undefined;

  constructor(
    private portalUserService: PortalUserService,
    private featureModuleService: FeatureModuleService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.portalUser = this.portalUserService.getPortalUser$().getValue();

    this.routes = this.router.config;

    this.portalUserService.portalRoutes = this.router.config;

    this.featureModuleGroups = this.featureModuleService.featureModuleGroups;
  }

  hasPermissionToRoute(route: string) {
    return this.portalUserService.hasPermissionToRoute(route);
  }
}
