<div class="container">
  <div>
    <button mat-flat-button (click)="goBack()">
      <span><mat-icon class="prev">chevron_left</mat-icon>PREV</span>
    </button>
    <h1>{{this.accountCommissionGroup?.commissionAccountNumber}} Commissions - {{(this.fromDate ?? 0) * 1000 | date}} to {{(this.toDate ?? 0) * 1000 | date}}</h1>
  </div>

  <div class="commissions">
    <table mat-table [dataSource]="this.dataSource" matSort class="mat-elevation-z8">

      <ng-container matColumnDef="commissionAccountNumber">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Commission Account Number</th>
        <td mat-cell *matCellDef="let commission">{{commission.commissionAccountNumber}}</td>
      </ng-container>

      <ng-container matColumnDef="productionWeek">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>P-Week</th>
        <td mat-cell *matCellDef="let commission">{{commission.productionWeek}}</td>
      </ng-container>

      <ng-container matColumnDef="eventDescription">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Event Name</th>
        <td mat-cell *matCellDef="let commission">{{commission.eventDescription}}</td>
      </ng-container>

      <ng-container matColumnDef="customer.firstName">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>First Name</th>
        <td mat-cell *matCellDef="let commission">{{commission.customer.firstName}}</td>
      </ng-container>

      <ng-container matColumnDef="customer.lastName">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Last Name</th>
        <td mat-cell *matCellDef="let commission">{{commission.customer.lastName}}</td>
      </ng-container>

      <ng-container matColumnDef="accountNumber">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Account Number</th>
        <td mat-cell *matCellDef="let commission">{{commission.accountNumber}}</td>
      </ng-container>

      <ng-container matColumnDef="orderNumber">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Order Number</th>
        <td mat-cell *matCellDef="let commission">{{commission.orderNumber}}</td>
      </ng-container>

      <ng-container matColumnDef="orderStatus">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Order Status</th>
        <td mat-cell *matCellDef="let commission">{{commission.orderStatus}}</td>
      </ng-container>

      <ng-container matColumnDef="commissionType">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Commission Type</th>
        <td mat-cell *matCellDef="let commission">{{commission.commissionType}}</td>
      </ng-container>

      <ng-container matColumnDef="commissionStatus">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Commission Status</th>
        <td mat-cell *matCellDef="let commission">{{commission.commissionStatus}}</td>
      </ng-container>

      <ng-container matColumnDef="rentalCommission">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Rental Commission</th>
        <td mat-cell *matCellDef="let commission">{{commission.rentalCommission | currency}}</td>
      </ng-container>

      <ng-container matColumnDef="purchaseCommission">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Purchase Commission</th>
        <td mat-cell *matCellDef="let commission">{{commission.purchaseCommission | currency }}</td>
      </ng-container>

      <ng-container matColumnDef="totalCommission">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Total Commission</th>
        <td mat-cell *matCellDef="let commission">{{commission.totalCommission | currency}}</td>
      </ng-container>

      <ng-container matColumnDef="details">
        <th mat-header-cell *matHeaderCellDef>Details</th>
        <td mat-cell *matCellDef="let commission">
          <a routerLink="/commission/details/{{commission.orderId}}">
            <mat-icon>remove_red_eye</mat-icon>
          </a>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>

  <mat-spinner *ngIf="showSpinner"></mat-spinner>

