import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  inject,
} from '@angular/core';
import { PortalUserService } from '../../services/portal-user/portal-user.service';
import { PORTAL_ENVIRONMENT } from '../../tokens';

@Component({
  selector: 'jfw-portal-header',
  templateUrl: './portal-header.component.html',
  styleUrls: ['./portal-header.component.scss'],
})
export class PortalHeaderComponent implements OnInit {
  @Input() portalTitle = '';
  @Output() menuClick = new EventEmitter<void>();
  servedEnvironment: string = 'none';
  environment = inject(PORTAL_ENVIRONMENT);

  ngOnInit(): void {
    this.setServeEnvironment();
    console.log(`ENV: ${this.servedEnvironment}`);
  }

  constructor(private portalUserService: PortalUserService) {}

  onClickSignOutButton(): void {
    this.portalUserService.signOut();
  }

  onMenuClick(): void {
    this.menuClick.emit();
  }

  setServeEnvironment() {
    this.servedEnvironment = this.environment.envVersion;
  }
}
