import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { Image } from 'common-types';
import { Subject, Subscription } from 'rxjs';

export enum MannequinImageType {
  Shirt = 'Shirt',
  CoatExtra = 'CoatExtra',
  BowTieExtra = 'BowTieExtra',
  WesternTieExtra = 'WesternTieExtra',
  Pant = 'Pant',
  WesternTie = 'WesternTie',
  WindsorTie = 'WindsorTie',
  Vest = 'Vest',
  ShirtExtra = 'ShirtExtra',
  Coat = 'Coat',
  BowTie = 'BowTie',
}

export enum MannequinAccessoryImageType {
  Shoes = 'Shoes',
  PocketSquare = 'PocketSquare',
  Socks = 'Socks',
  Suspenders = 'Suspenders',
  Miscellaneous = 'Miscellaneous',
  Jewelry = 'Jewelry',
}

@Component({
  selector: 'app-jfw-lib-images-form',
  templateUrl: './images-form.component.html',
  styleUrls: ['./images-form.component.scss'],
})
export class ImagesFormComponent implements OnInit, OnDestroy {
  @ViewChild('formDirective') formDirective!: FormGroupDirective;
  @Input() title!: string;
  @Input() images!: Image[];
  @Input() reset$!: Subject<void>;
  @Input() enableMannequinImage: boolean = false;
  @Output() imagesEvent = new EventEmitter<Image[]>();

  subscription = new Subscription();

  keys = Object.keys;
  MannequinImageTypeEnum = MannequinImageType;
  MannequinAccessoryImageTypeEnum = MannequinAccessoryImageType;

  form = new FormGroup({
    src: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    alt: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    mannequin: new FormControl('', {
      nonNullable: true,
      validators: [],
    }),
  });

  constructor() {}

  ngOnInit(): void {
    this.subscription.add(
      this.reset$.subscribe({ next: () => this.onReset() })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onSubmit(): void {
    if (this.form.valid) {
      const image: Image = {
        src: this.form.controls.src.value,
        alt: this.form.controls.alt.value,
      };

      if (this.form.controls.mannequin.value) {
        image.mannequinImage = <
          MannequinImageType | MannequinAccessoryImageType
        >this.form.controls.mannequin.value;
      }
      this.images.push(image);
      this.imagesEvent.emit(this.images);
      this.formDirective.resetForm();
      this.form.reset();
    }
  }

  public onDelete(index: number): void {
    this.images.splice(index, 1);
  }

  public onReset(): void {
    this.images = [];
    this.imagesEvent.emit(this.images);
    this.form.reset();
  }
}
