import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountCommissionGrouping } from 'common-types';
import { Subscription } from 'rxjs';
import { CommissionService } from '../../../services/commission/commission.service';

@Component({
  selector: 'jfw-paid-group-statement',
  templateUrl: './paid-group-statement.component.html',
  styleUrls: ['./paid-group-statement.component.scss'],
})
export class PaidGroupStatementComponent implements OnInit, OnDestroy {
  showSpinner = true;
  accountCommissionGroupId: string | undefined;
  accountCommissionGrouping!: AccountCommissionGrouping;
  referralCommissionTotal: number = 0;
  organicCommissionTotal: number = 0;
  customLinkCommissionTotal: number = 0;
  pastDueBalance: number = 0;
  totalCommission: number = 0;
  subscription = new Subscription();
  constructor(
    private commissionService: CommissionService,
    private location: Location,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.accountCommissionGroupId = this.route.snapshot.params.id;
    if (this.accountCommissionGroupId) {
      this.subscription.add(
        this.commissionService
          .getPaidCommissionGroupById(this.accountCommissionGroupId)
          .subscribe((accountCommissionGrouping) => {
            this.accountCommissionGrouping = accountCommissionGrouping;
            this.calculateCommissionTotals();
            this.showSpinner = false;
          }),
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  calculateCommissionTotals() {
    this.accountCommissionGrouping.commissions.forEach((commission) => {
      if (commission.commissionType.toString().toUpperCase() === 'REFERRAL') {
        this.referralCommissionTotal += commission.totalCommission;
      }

      if (commission.commissionType.toString().toUpperCase() === 'ORGANIC') {
        this.organicCommissionTotal += commission.totalCommission;
      }

      if (
        commission.commissionType.toString().toUpperCase() === 'CUSTOM_LINK'
      ) {
        this.customLinkCommissionTotal += commission.totalCommission;
      }

      console.log('commission', commission);
    });

    if (
      this.accountCommissionGrouping.pastDueBalance &&
      this.accountCommissionGrouping.pastDueBalance > 0
    ) {
      this.pastDueBalance = this.accountCommissionGrouping.pastDueBalance * -1;
    }

    this.totalCommission =
      this.referralCommissionTotal +
      this.organicCommissionTotal +
      this.customLinkCommissionTotal +
      this.pastDueBalance;
  }

  goBack() {
    this.location.back();
  }

  printPreview() {
    window.print();
  }
}
