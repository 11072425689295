import { ConsoleLogFlags } from './console-log-flags.type';
import { disableConsole } from './disable-console';
import { getConsoleQueryParams } from './get-console-query-params';

/**
 * Disables console output if isDisableConsole flag is set to true, but also checks url query params (and sessionStorage) to override isDisableConsole value.
 * This should be called at the top of the main.ts file in order to catch all console output that happens before the app.component is initialized
 * (such as output from services, guards, etc.).
 * @param isDisableConsole Pass in the environment.disableConsole variable.  This should be true for all Prod builds.
 * @example
 * // main.ts
 * import { initDisableConsole } from '@jfw-library/shared/ui-logic';
 *
 * // import or inject environment variable
 *
 * // call initDisableConsole at the top of main.ts
 * initDisableConsole(environment.disableConsole);
 */
export const initDisableConsole = (isDisableConsole: boolean | undefined) => {
  // if isDisableConsole is undefined, then disable all console output by default
  if (isDisableConsole === undefined) {
    console.warn(
      `🖥️ Console output is %c disabled %c by default.`,
      'color: green;',
      'color: black;'
    );
    return disableConsole(allLogs);
  }

  if (isDisableConsole) {
    console.warn(
      `🖥️ Console output is %c disabled %c for Prod.`,
      'color: red;',
      'color: black;'
    );

    // override if query params are set or sessionStorage is set.  Note: queryParams and sessionsStorage are only used to enable console output, not to disable it.
    const queryParamFlags = getConsoleQueryParams(); // all flags will be false if no query params are set or if window is undefined (like in SSR)
    const {
      log: queryLog,
      logAll: queryLogAll,
      warn: queryWarn,
      error: queryError,
      info: queryInfo,
      debug: queryDebug,
    } = queryParamFlags;
    const anyQueryParamFlags =
      queryLog ||
      queryLogAll ||
      queryWarn ||
      queryError ||
      queryInfo ||
      queryDebug;

    // console.log('queryParamFlags: ', queryParamFlags);

    const sessionStorageFlagsString = sessionStorage.getItem('consoleLogFlags');
    const sessionStorageFlags = sessionStorageFlagsString
      ? (JSON.parse(sessionStorageFlagsString) as ConsoleLogFlags)
      : null;
    // console.log('sessionStorageFlags: ', sessionStorageFlags);
    const {
      log: storedLog,
      logAll: storedLogAll,
      warn: storedWarn,
      error: storedError,
      info: storedInfo,
      debug: storedDebug,
    } = sessionStorageFlags ?? {
      log: false,
      logAll: false,
      warn: false,
      error: false,
      info: false,
      debug: false,
    };

    // enable if has queryParam or sessionStorage flag
    const log = queryLog || storedLog;
    const logAll = queryLogAll || storedLogAll;
    const warn = queryWarn || storedWarn;
    const error = queryError || storedError;
    const info = queryInfo || storedInfo;
    const debug = queryDebug || storedDebug;

    const flags: ConsoleLogFlags = { log, logAll, warn, error, info, debug };

    if (logAll) {
      // override the isDisableConsole==true if logAll is set, and leave all console functions enabled. (do nothing)
      console.warn(
        `🖥️ Console output is %c enabled %c on init by query params or sessionStorage for ALL console functions.`,
        'color: green;',
        'color: black;'
      );
      sessionStorage.setItem('consoleLogFlags', JSON.stringify(allLogs));
      return allLogs;
    }

    // not logAll, so notify the user of the specific flags that are set
    logFlags(flags);

    // store the queryParam flags in sessionStorage so that they persist on page refresh, but only if there are query params
    if (anyQueryParamFlags)
      sessionStorage.setItem('consoleLogFlags', JSON.stringify(flags));

    // disable console based on flags.  Note: if no query params are present and no sessionStorage is present, then all flags will be false, so all console output will be disabled (following the isDisableConsole == true)
    return disableConsole(flags);
  }

  // if isDisableConsole is false, then all console output will be left enabled (do nothing).
  console.warn(
    `🖥️ All console output is %c enabled %c on init.`,
    'color: green;',
    'color: black;'
  );
  return null;
};

/** Utility object for disabling all console output. */
const allLogs: ConsoleLogFlags = {
  log: true,
  logAll: true,
  warn: true,
  error: true,
  info: true,
  debug: true,
};

const logFlags = (flags: ConsoleLogFlags) => {
  const { log, warn, error, info, debug } = flags;
  const specificFlags = { log, warn, error, info, debug };
  const anyFlags = log || warn || error || info || debug;

  if (anyFlags) {
    // if any flags are set, then log a warning
    for (const key in specificFlags) {
      const flag = specificFlags[key as keyof typeof specificFlags];
      if (flag) {
        console.warn(
          `🖥️ console.${key} output is %c enabled %c on init by query params or sessionStorage.`,
          'color: green;',
          'color: black;'
        );
        continue;
      } else {
        console.warn(
          `🖥️ console.${key} output is %c disabled %c on init by query params or sessionStorage.`,
          'color: red;',
          'color: black;'
        );
        continue;
      }
    }
  }
  // else { // if no flags are set, then log a warning that all console output is disabled
  //   console.warn(`🖥️ Console output is %c disabled %c on init.`, 'color: red;', 'color: black;');
  // }
};
