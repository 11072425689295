<div class="container">
  <div>
    <button mat-flat-button (click)="goBack()">
      <span><mat-icon class="prev">chevron_left</mat-icon>PREV</span>
    </button>
    <h1>Manage Unpaid Commissions</h1>
  </div>
  <div class="form-container">
    <form [formGroup]="searchForm" (ngSubmit)="onSubmit()">
      <mat-form-field appearance="fill">
        <mat-label>Event Date</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input
            matStartDate
            formControlName="dateStart"
            placeholder="Start Date"
          />
          <input matEndDate formControlName="dateEnd" placeholder="End Date" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
      <button type="submit" mat-icon-button><mat-icon>search</mat-icon></button>
    </form>
  </div>
  <div class="commissions" *ngIf="this.accountCommissionGroups !== undefined">
    <table
      mat-table
      [dataSource]="this.accountCommissionGroups"
      class="mat-elevation-z8"
    >
      <ng-container matColumnDef="commissionAccountNumber">
        <th mat-header-cell *matHeaderCellDef>Commission Account Number</th>
        <td mat-cell *matCellDef="let commission">
          {{ commission.commissionAccountNumber }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="rentalCommission">
        <th mat-header-cell *matHeaderCellDef>Rental Commission</th>
        <td mat-cell *matCellDef="let commission">
          {{ commission.rentalCommission | currency }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="purchaseCommission">
        <th mat-header-cell *matHeaderCellDef>Purchase Commission</th>
        <td mat-cell *matCellDef="let commission">
          {{ commission.purchaseCommission | currency }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="totalCommission">
        <th mat-header-cell *matHeaderCellDef>Total Commission</th>
        <td mat-cell *matCellDef="let commission">
          {{ commission.totalCommission | currency }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="details">
        <th mat-header-cell *matHeaderCellDef>Details</th>
        <td mat-cell *matCellDef="let commission">
          <a
            routerLink="/commission/group/detail/{{
              commission.commissionAccountNumber
            }}/{{ commission.approvedFrom }}/{{ commission.approvedTo }}"
          >
            <mat-icon>remove_red_eye</mat-icon>
          </a>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="pastDueBalance">
        <th mat-header-cell *matHeaderCellDef>Past Due</th>
        <td mat-cell *matCellDef="let commission">
          <input
            matInput
            type="number"
            placeholder="Past Due"
            [(ngModel)]="commission.pastDueBalance"
          />
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="approved">
        <th mat-header-cell *matHeaderCellDef>Approve</th>
        <td mat-cell *matCellDef="let commission">
          <mat-checkbox
            [disabled]="isSaving"
            [ngClass]="isSaving ? 'disabled' : ''"
            (click)="toggleApproveAccount(commission)"
          ></mat-checkbox>
        </td>
        <td mat-footer-cell *matFooterCellDef>
          <button
            [disabled]="isSaving"
            mat-raised-button
            (click)="approveCommissions()"
          >
            Submit
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
    </table>
  </div>
</div>

<mat-spinner *ngIf="showSpinner"></mat-spinner>
