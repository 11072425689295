<!-- deploy 4 -->
<ng-container *ngIf="portalUser">
  <jfw-portal-header
    [class]="hideHeaderOnPrint ? 'hide-on-print' : ''"
    [portalTitle]="portalTitle"
    (menuClick)="drawer.toggle()"
  ></jfw-portal-header>
</ng-container>

<mat-drawer-container>
  <mat-drawer #drawer mode="side" fullscreen>
    <jfw-portal-navigation></jfw-portal-navigation>
  </mat-drawer>

  <mat-drawer-content id="matDrawerContent" class="mat-drawer-content">
    <router-outlet></router-outlet>
  </mat-drawer-content>
</mat-drawer-container>
