import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  BasePortalComponent,
  FeatureModuleService,
  PortalUserService,
} from '@jfw-library/platform/common-components/portal';
import { PortalFeatureModuleGroup } from 'common-types';
import { filter, Subscription } from 'rxjs';

// trigger 20

@Component({
  selector: 'jfw-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends BasePortalComponent implements OnDestroy {
  override portalTitle = 'JFW Accounting Portal';

  hideHeaderOnPrint: boolean = true;
  override subscription = new Subscription();
  // constructor(private route: ActivatedRoute, private router: Router) {
  //   super(portalUserService: PortalUserService, featureModuleService: FeatureModuleService);
  // }

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    portalUserService: PortalUserService,
    featureModuleService: FeatureModuleService,
    httpClient: HttpClient,
  ) {
    super(portalUserService, featureModuleService, httpClient);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.subscription.add(
      this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe(() => {
          // Your code to execute on route change
          this.checkUrlForPrint(); // Call your checkUrl function here
        }),
    );
  }

  override ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  override featureModuleGroups: PortalFeatureModuleGroup[] = [
    {
      title: 'Accounts',
      featureModules: [
        {
          title: 'List of Accounts',
          description: 'List of all accounts',
          route: '/account/manage',
        },
        // {
        //   title: 'Create Dealer User',
        //   description: 'Create a new dealer user',
        //   route: '/account/create-user',
        // },
        // {
        //   title: 'Pending Dealer Users',
        //   description:
        //     'List of users that have not accepted the email invitation',
        //   route: '/account/pending-users',
        // },
      ],
    },
    {
      title: 'Commissions',
      featureModules: [
        {
          title: 'Search Commissions',
          description: 'Search Commissions By Event Date',
          route: '/commission/search',
        },
        {
          title: 'Manage Unpaid Commissions',
          description: 'Review Ecommerce Commissions',
          route: '/commission/approve',
        },
        {
          title: 'Approved Commissions',
          description: 'Review Approved Commissions',
          route: '/commission/approved',
        },
        {
          title: 'Paid Group Commissions',
          description: 'Review Paid Group Commissions',
          route: '/commission/group/paid',
        },
      ],
    },
  ];

  checkUrlForPrint() {
    const url = this.router.url;

    console.log('URL:', url);

    if (url.includes('/commission/group/statement')) {
      this.hideHeaderOnPrint = true;
    } else {
      this.hideHeaderOnPrint = false;
    }
  }
}
