import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, isDevMode } from '@angular/core';
import { getApp } from '@angular/fire/app';
import {
  ReCaptchaEnterpriseProvider,
  initializeAppCheck,
  provideAppCheck,
} from '@angular/fire/app-check';
import { getPerformance, providePerformance } from '@angular/fire/performance';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes, mapToCanActivate } from '@angular/router';
import { AppCheckInterceptor } from '@jfw-library/shared/app-check';
import { BasePortalComponent } from './base-portal.component';
import { PortalHeaderComponent } from './components/portal-header/portal-header.component';
import { PortalHomeComponent } from './components/portal-home/portal-home.component';
import { PortalNavigationComponent } from './components/portal-navigation/portal-navigation.component';
import { PortalSignInComponent } from './components/portal-sign-in/portal-sign-in.component';
import { PortalGuard } from './guards/portal/portal.guard';
import { PORTAL_ENVIRONMENT } from './tokens/portal-environment.token';

// (function () {
//   var originalSend = XMLHttpRequest.prototype.send;
//   var originalFetch = window.fetch;

//   // Override XMLHttpRequest.send
//   XMLHttpRequest.prototype.send = function (req) {
//     console.log('XMLHttpRequest.prototype.send() called with:', req);
//     console.log('XMLHttpRequest sent:', arguments);
//     return originalSend.apply(this, [arguments[0]]);
//   };

//   // Override fetch
//   window.fetch = function (req) {
//     console.log('Fetch called:', req);
//     console.log('Fetch called:', arguments);
//     return originalFetch.apply(this, [arguments[0]]);
//   };
// })();

/**
 * This is the environment.ts file for the portal.
 * It has a type of PortalEnvironment, but when injected in the portal's app.module, it is an object literal with the portal-specific type.
 */
// export const PORTAL_ENVIRONMENT = new InjectionToken<PlatformPortalEnvironment>('Portal environment.ts file');

// export const providePortalEnvironment = <T>(environment: T) => {
//   return {
//     provide: PORTAL_ENVIRONMENT as InjectionToken<T>,
//     useValue: environment,
//   };
// }
// export const providePortalEnvironment = <T>(environment: T) => {
//   return {
//     provide: PORTAL_ENVIRONMENT as InjectionToken<T>,
//     useValue: environment,
//   };
// }

// export const initEnvironmentToken = () => {
//   const token = new InjectionToken<any>('Portal environment.ts file');
//   return {

//     getToken: <T>() => {
//       const env = inject<InjectionToken<T>>(token);
//       if (!env) {
//         if (isDevMode()) alert('environment.ts not defined');
//         throw new Error('environment.ts not defined');
//       }
//       return env;
//     }
//   }

// }

// const ENVIRONMENT = initEnvironmentToken();

// const environment = ENVIRONMENT.getToken<CustomerServicePortalEnvironment>();

//   const token = new InjectionToken<T>('environment');

//   const factory = () => {
//     const env = inject(token);
//     if (!env) {
//       if (isDevMode()) alert('environment.ts not defined');
//       throw new Error('environment.ts not defined');
//     }
//     return env;
//   }

//   return { token, factory };
// }

// export const ENVIRONMENT = getEnvironmentToken<{ recaptchaAppCheckKey: string }>();

// export const ENVIRO = new InjectionToken<any>('enviro', {
//   providedIn: 'root',
//   factory: <T>() => inject(ENVIRONMENT),
// });

const routes: Routes = [
  {
    path: 'sign-in',
    component: PortalSignInComponent,
    canActivate: mapToCanActivate([PortalGuard]),
  },
  {
    path: 'home',
    component: PortalHomeComponent,
    canActivate: mapToCanActivate([PortalGuard]),
  },
];

@NgModule({
  declarations: [
    BasePortalComponent,
    PortalHeaderComponent,
    PortalNavigationComponent,
    PortalSignInComponent,
    PortalHomeComponent,
  ],
  imports: [
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    BrowserAnimationsModule,
    MatExpansionModule,
    MatListModule,
    MatCardModule,
    MatSidenavModule,
    RouterModule.forChild(routes),
    CommonModule,
  ],
  exports: [
    BasePortalComponent,
    PortalHeaderComponent,
    PortalNavigationComponent,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    BrowserAnimationsModule,
    MatExpansionModule,
    MatListModule,
    MatCardModule,
    MatSidenavModule,
    RouterModule,
  ],
  providers: [
    provideAppCheck((injector) => {
      const key = injector.get(PORTAL_ENVIRONMENT)?.recaptchaAppCheckKey;
      if (!key) {
        if (isDevMode())
          alert('recaptchaAppCheckKey not defined in environment.ts');
        throw new Error('recaptchaAppCheckKey not defined in environment.ts');
      }
      // console.log('appCheck key:', key);
      return initializeAppCheck(getApp(), {
        provider: new ReCaptchaEnterpriseProvider(key),
        isTokenAutoRefreshEnabled: false, // this is also the default
      });
    }),
    providePerformance(() => getPerformance()),
    { provide: HTTP_INTERCEPTORS, useClass: AppCheckInterceptor, multi: true },
  ],
})
export class PortalModule {}
