/// This functionality was moved into the appCheckInterceptor directly.  This file is no longer needed.
import { Injectable, OnDestroy, inject, isDevMode } from '@angular/core';
import { AppCheck, getToken, onTokenChanged } from '@angular/fire/app-check';
import { Subscription, catchError, finalize, from, tap, throwError } from 'rxjs';

const DEBUG = {
  forceRefresh: isDevMode() && false, // USE THIS SPARINGLY.  set to true to force refresh the App Check token on every request.  Since App Check tokens are cached for 1 hour, this is useful for testing.
  error: isDevMode() && false // set to true to simulate an error when getting the AppCheck token.  This doesn't actually create an error within AppCheck getToken(), but instead just calls the onTokenError method when the token is changed. This is useful for testing.
};


// const getHeaders = (token: string) => new HttpHeaders({
//   'X-Firebase-AppCheck': token
// });

// type AppCheckHeader = ReturnType<typeof getHeaders> | null;

/**
 * This service is used to get the App Check token and add it to the headers of an http request.
 * You should not need to use this service directly.  It is used by the [AppCheckInterceptor](../../interceptors/app-check/app-check.interceptor.ts).
 *
 * See our full [App Check Documentation](../../../../../documentation/app-check.md) for more details.
 */
@Injectable({ providedIn: 'root' })
export class AppCheckService implements OnDestroy {

  private appCheck: AppCheck = inject(AppCheck);
  private subscription: Subscription = new Subscription();

  constructor() {

    if (DEBUG.error) {
      console.warn("DEBUG.error is ON for AppCheckService.  Forcing an error when getting the AppCheck token.");
    }

    // console.log("appCheck: ", this.appCheck);

    this.subscription.add(
      onTokenChanged(
        this.appCheck,
        (tokenChangeResult) => DEBUG.error ? this.onTokenError(new Error('TESTING an AppCheck error')) : this.onTokenChange(tokenChangeResult.token),
        (error) => this.onTokenError(error)
      )
    );

    // this.subscription.add(
    //   appCheckInstance$.pipe(
    //     tap(appCheck => {
    //       const date = new Date();
    //       const display = date.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }) + " " + date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false }) + "." + date.getMilliseconds();

    //       console.log(`%c ${display} -- App Check instance.`, 'color: #f1fffa; background-color: #00A36C;');
    //       // console.log("App Check instance: ", appCheck);

    //     })
    //   ).subscribe()
    // );
  }

  private onTokenChange(token: string) {
    const date = new Date();
    const display = date.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }) + " " + date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false }) + "." + date.getMilliseconds();

    console.log(`%c ${display} -- App Check token changed.`, 'color: #f1fffa; background-color: #00A36C;');

    return
  }

  /** Catch an error from getting an AppCheck token.
   * This can happen if ReCAPTCHA fails.
   * For example, this error in the console: FirebaseError: AppCheck: ReCAPTCHA error. (appCheck/recaptcha-error)
   * Offer the user an option to reload the page, since this seems to resolve
   * that error when it is the result of a browser window being open too long (we think).
   * */
  private onTokenError(error: Error) {
    if (DEBUG.error) {
      console.error("SIMULATED App Check token error", error)
    } else {
      console.error("App Check token error: ", error);
    }

    /// Using a confirm here because it is a blocking dialog that will pause the code execution until the user responds
    /// and not create an infinite reload loop.
    if (confirm("Sorry, there was an error with AppCheck.  The page needs to refresh.")) {
      location.reload();
    }

  }

  /**
   * Use the firebase getToken method to get the App Check token.
   *
   * It will first check for a token in cache, but if the getToken returns null then it will attempt to get a new token using forceRefresh set to true.
   *
   * @param forceRefresh whether or not to force refresh the App Check token.  Defaults to false.
   * This is used for testing when you want to force refresh the token on every request.
   * Since App Check tokens are cached for 1 hour, this is useful for testing.
   * Setting DEBUG.forceRefresh to true in the [AppCheckInterceptor](../../interceptors/app-check/app-check.interceptor.ts) will force refresh the token on every request.
   */
  getAppCheckToken(forceRefresh: boolean = false) {
  // console.log("getAppCheckToken()");

    // forceRefresh is not behaving as expected.  It seems that once you pass forceRefresh=true, it continues to force refresh the token on every request even after you pass forceRefresh=false.
    return from(getToken(this.appCheck, forceRefresh)).pipe(
      // keepUnstableUntilFirst,
      tap(appCheckTokenResponse => {
        const date = new Date();
        const display = date.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }) + " " + date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false }) + "." + date.getMilliseconds();

        if (forceRefresh) {
          console.log(`%c ${display} -- App Check token after forceRefresh: ${!!appCheckTokenResponse.token ? "Successful." : "Fail"}`, 'color: white; background-color: #00A36C; ');
        } else {
          console.log(`%c ${display} -- App Check token 1st attempt (cached token): ${!!appCheckTokenResponse.token ? "Successful." : "Fail"}`, 'color: white; background-color: #00A36C; ');
        }
      }),
      // If the token is null, try to force refresh it
      // mergeMap(appCheckTokenResponse => iif(() => appCheckTokenResponse !== null,
      //   of(appCheckTokenResponse),
      //   from(getToken(this.appCheck, /* forceRefresh= */ true)).pipe(
      //     tap(appCheckTokenResponse2 => {
      //       const date = new Date();
      //       const display = date.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }) + " " + date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false }) + "." + date.getMilliseconds();
      //       console.log(`%c ${display} -- App Check token 2nd attempt (after force refresh): ${!!appCheckTokenResponse2.token ? "Successful." : "Fail"}`, 'color: white; background-color: #00A36C; ')
      //     }),
      //   )
      // )),
      catchError(err => {
        const date = new Date();
        const display = date.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }) + " " + date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false }) + "." + date.getMilliseconds();
        console.error(`%c ${display} -- Error getting App Check token`, err, 'color: white; background-color: #00A36C; ');
        return throwError(() => err);
      }),
      finalize(() => {
        const date = new Date();
        const display = date.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }) + " " + date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false }) + "." + date.getMilliseconds();
        console.log(`%c ${display} -- getAppCheckToken() complete.`, 'color: white; background-color: #00A36C; ');
      }),
    );
  }


  // private getAppCheckHeader() {

  //   return this.getAppCheckToken().pipe(
  //     map(appCheckTokenResponse => {
  //       if (appCheckTokenResponse) {
  //         console.log("App Check token received in getAppCheckHeader()");
  //         const appCheckHeader = [
  //           'X-Firebase-AppCheck', appCheckTokenResponse.token
  //         ];

  //         console.log("App Check header: ", appCheckHeader);

  //         return appCheckHeader;
  //       } else {
  //         console.error("No App Check token received in getAppCheckHeader()");
  //         return null;
  //       }

  //     })
  //   )
  // }

  ngOnDestroy(): void {
    console.log("AppCheckService ngOnDestroy()");
    this.subscription.unsubscribe();
  }


}
