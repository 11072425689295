import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDrawerContent } from '@angular/material/sidenav';
import { AccountService } from '@jfw-library/platform/services';
import { AccountMaster } from 'common-types';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
  selector: 'jfw-manage-v2',
  templateUrl: './manage-v2.component.html',
  styleUrls: ['./manage-v2.component.scss'],
})
export class ManageV2Component implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('#matDrawerContent') matDrawerContent!: MatDrawerContent;
  @ViewChild('tableTop') tableTop!: ElementRef;
  @ViewChild('tableAccounts') tableAccounts!: ElementRef;
  accounts$ = new BehaviorSubject<AccountMaster[] | undefined>(undefined);
  subscription = new Subscription();
  // documents: AccountMaster[] = [];
  // lastDocumentId: string | undefined = '0';

  documents: AccountMaster[] = JSON.parse(
    localStorage.getItem('lastAccountDocuments') || '[]',
  );
  lastDocumentId: string | undefined =
    localStorage.getItem('lastAccountDocumentId') || '0';

  lastAttemptedDocumentId: string | undefined = '';

  loading = false;

  displayedColumns = ['accountNumber', 'accountName', 'viewEdit'];

  noMoreAccounts: boolean = false;

  startingPositionTop: number = 0;
  screenHeight: number = 0;

  constructor(
    private accountService: AccountService,
    private elementRef: ElementRef,
    private changeDetectorRefs: ChangeDetectorRef,
  ) {}

  @HostListener('window:scroll', [])
  onWindowScroll() {
    console.log('Does this work?');
  }

  ngOnInit(): void {
    console.log('OnInit');
    const hostElement = this.elementRef.nativeElement;
    hostElement.style.overflow = 'auto';

    //this.loadDocuments();

    if (this.documents.length === 0) {
      this.loadDocuments();
    } else {
      this.accounts$.next(this.documents);
    }

    // if (sessionStorage.getItem('manage-scroll')) {
    //   //window.scrollTo(0, 100);
    //   this.matDrawerContent.scrollTo({
    //     top: 100,
    //     behavior: 'smooth',
    //   });
    // }

    document
      .getElementsByClassName('mat-drawer-content')
      .item(0)!
      .addEventListener('scroll', (event) => {
        setTimeout(() => {
          //console.log('It works!');
          const tableTopRect =
            this.tableTop.nativeElement.getBoundingClientRect();
          //console.log(tableTopRect);

          const sticky = document.getElementById('sticky');

          const scrollPosition =
            window.pageYOffset ||
            document.documentElement.scrollTop ||
            document.body.scrollTop ||
            0;

          // if (sticky) {
          //   let stickyStyle = window.getComputedStyle(sticky);
          //   let positionType: string = stickyStyle.getPropertyValue('position');

          //   console.log(scrollPosition);
          // }

          // const tableAccountsRect =
          //   this.tableAccounts.nativeElement.getBoundingClientRect();
          // console.log(tableAccountsRect);

          //console.log('screen height: ' + this.screenHeight);

          const tableTopPosTop = tableTopRect.top;
          //console.log('tableTop Top:' + tableTopPosTop);

          const diff = tableTopRect.top - this.screenHeight;
          //console.log('Diff:' + diff);

          sessionStorage.setItem('manage-scroll', diff.toString());

          if (!this.noMoreAccounts && !this.loading && diff < 50) {
            //console.log('LOAD!!');
            this.startingPositionTop = tableTopRect.top;
            this.loadDocuments();
          }
        });

        //this.startingPositionTop = rect.top;
        // if (event.target) {
        //   console.log(event.target);
        // }
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  // @HostListener('.mat-drawer.content:scroll', ['$event'])
  // onScroll(event: any) {
  //   // Your scroll detection logic here
  //   console.log('scrolling');
  // }

  ngAfterViewInit(): void {
    console.log('AfterViewInit');
    setTimeout(() => {
      if (this.tableTop !== undefined) {
        const startingRect =
          this.tableTop.nativeElement.getBoundingClientRect();

        this.startingPositionTop = startingRect.top;

        this.screenHeight = window.innerHeight - 64;
        console.log('Height:' + this.screenHeight);
      }
    });
  }

  ngAfterContentInit(): void {
    console.log('AfterContentInit');
  }

  async loadDocuments() {
    if (this.loading) {
      return;
    }

    this.loading = true;

    if (this.lastAttemptedDocumentId === this.lastDocumentId) {
      console.log('Already tried that lastDocumentId');
      this.loading = false;
      return;
    }

    console.log('LOAD!!');

    this.lastAttemptedDocumentId = this.lastDocumentId;

    const response = await this.accountService.apiGetAccountsPaginatedPromise(
      10,
      '0',
      this.lastDocumentId,
    );

    if (response.documents !== undefined && response.documents.length > 0) {
      console.log('response');
      console.log(response);
      this.lastDocumentId =
        response.documents[response.documents.length - 1].firestoreDocumentId;
      this.documents = this.documents.concat(response.documents);
      this.accounts$.next(this.documents);

      if (this.lastDocumentId !== undefined) {
        //localStorage.setItem('lastAccountDocumentId', this.lastDocumentId);
      }

      if (this.documents !== undefined) {
        // localStorage.setItem(
        //   'lastAccountDocuments',
        //   JSON.stringify(this.documents)
        // );
      }
    } else {
      this.noMoreAccounts = true;
    }

    this.loading = false;

    this.changeDetectorRefs.detectChanges();
  }

  insertAccount(accountData: string) {
    console.log(accountData);
    const account: AccountMaster = JSON.parse(accountData);
    this.subscription.add(
      this.accountService.apiCreateAccount(account).subscribe((response) => {
        console.log(response);
      }),
    );
  }
}
