import { Location } from '@angular/common';
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountCommissionGrouping, Commission } from 'common-types';
import { Subscription } from 'rxjs';
import { CommissionService } from '../../../services/commission/commission.service';

@Component({
  selector: 'jfw-commission-group-detail',
  templateUrl: './commission-group-detail.component.html',
  styleUrls: ['./commission-group-detail.component.scss'],
})
export class CommissionGroupDetailComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  commissionAccountNumber!: string | null;
  fromDate!: number | null;
  toDate!: number | null;
  accountCommissionGroup: AccountCommissionGrouping | undefined;
  showSpinner = false;
  public dataSource = new MatTableDataSource<Commission>();

  displayedColumns: string[] = [
    'commissionAccountNumber',
    'productionWeek',
    'eventDescription',
    'customer.firstName',
    'customer.lastName',
    'accountNumber',
    'orderNumber',
    'orderStatus',
    'commissionType',
    'commissionStatus',
    'rentalCommission',
    'purchaseCommission',
    'totalCommission',
    'details',
  ];

  subscription = new Subscription();
  @ViewChild(MatSort) private sort: MatSort | undefined;

  ngAfterViewInit() {
    if (this.sort) {
      this.dataSource.sort = this.sort;
      console.log(this.dataSource);
    }
  }

  constructor(
    private commissionService: CommissionService,
    private location: Location,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.commissionAccountNumber = this.route.snapshot.paramMap.get(
      'commissionAccountNumber',
    );
    this.fromDate = parseInt(
      this.route.snapshot.paramMap.get('fromDate') ?? '',
    );
    this.toDate = parseInt(this.route.snapshot.paramMap.get('toDate') ?? '');
    if (
      this.commissionAccountNumber !== null &&
      this.fromDate !== null &&
      this.toDate !== null
    ) {
      this.showSpinner = true;
      this.subscription.add(
        this.commissionService
          .getAccountCommissionGroupByRange(
            this.commissionAccountNumber,
            this.fromDate.toString(),
            this.toDate.toString(),
          )
          .subscribe((ob) => {
            this.accountCommissionGroup = ob;
            this.dataSource.data = ob.commissions;
            this.dataSource.sortingDataAccessor = (item, property) => {
              return this.getPropertyByPath(item, property) as any;
            };
            if (this.sort) {
              this.dataSource.sort = this.sort;
            }
            this.showSpinner = false;
          }),
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getPropertyByPath(obj: Object, pathString: string) {
    return pathString.split('.').reduce((o, i) => o[i as keyof Object], obj);
  }

  goBack() {
    this.location.back();
  }
}
