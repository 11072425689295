import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'jfw-approve-success',
  templateUrl: './approve-success.component.html',
  styleUrls: ['./approve-success.component.scss'],
})
export class ApproveSuccessComponent {

  constructor(
    private router: Router
  ) {}


  goHome() {
    this.router.navigate(['/home']);
  }

}
