import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccountCommissionGrouping, Commission } from 'common-types';
import { environment } from 'portals/accounting/src/environments/environment';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})
export class CommissionService {
  private COMMISSION_REST_API_SERVER =
    environment.commission_rest_api_server_url;
  private reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  constructor(private httpClient: HttpClient) {}

  public setUnpaidCommissionsSearchParams(
    startDate: number,
    endDate: number
  ): void {
    localStorage.setItem(
      'getUnapprovedCommissionsParams',
      JSON.stringify({ startDate, endDate })
    );
  }

  public setPaidCommissionsSearchParams(
    startDate: number,
    endDate: number
  ): void {
    localStorage.setItem(
      'getPaidCommissionsSearchParams',
      JSON.stringify({ startDate, endDate })
    );
  }

  public setCommissionsSearchParams(startDate: number, endDate: number): void {
    localStorage.setItem(
      'getCommissionsSearchParams',
      JSON.stringify({ startDate, endDate })
    );
  }

  public getUnpaidCommissionsSearchParams(): string | null {
    return localStorage.getItem('getUnapprovedCommissionsParams');
  }

  public getPaidCommissionsSearchParams(): string | null {
    return localStorage.getItem('getPaidCommissionsSearchParams');
  }

  public getCommissionsSearchParams(): string | null {
    return localStorage.getItem('getCommissionsSearchParams');
  }

  public getCommissionById(commissionId: string): Observable<Commission> {
    return this.httpClient.get<Commission>(
      this.COMMISSION_REST_API_SERVER + '/commissionId/' + commissionId,
      {
        headers: this.reqHeader,
      }
    );
  }

  public getCommissionByOrderId(orderId: string): Observable<Commission> {
    return this.httpClient.get<Commission>(
      this.COMMISSION_REST_API_SERVER + '/orderId/' + orderId,
      {
        headers: this.reqHeader,
      }
    );
  }

  public searchCommissions(
    fromDate: number,
    toDate: number
  ): Observable<Array<Commission>> {
    return this.httpClient.get<Array<Commission>>(
      this.COMMISSION_REST_API_SERVER + '/search/' + fromDate + '/' + toDate,
      {
        headers: this.reqHeader,
      }
    );
  }

  public getUnapprovedCommissions(
    fromDate: number,
    toDate: number
  ): Observable<Array<AccountCommissionGrouping>> {
    return this.httpClient.get<Array<AccountCommissionGrouping>>(
      this.COMMISSION_REST_API_SERVER +
        '/unapproved-by-account/' +
        fromDate +
        '/' +
        toDate,
      {
        headers: this.reqHeader,
      }
    );
  }

  public getApprovedCommissions(
    fromDate: number,
    toDate: number
  ): Observable<Array<AccountCommissionGrouping>> {
    return this.httpClient.get<Array<AccountCommissionGrouping>>(
      this.COMMISSION_REST_API_SERVER +
        '/approved-by-account/' +
        fromDate +
        '/' +
        toDate,
      {
        headers: this.reqHeader,
      }
    );
  }

  public approveAccountCommissionGroups(
    accountCommissionGroups: Array<AccountCommissionGrouping>
  ) {
    return this.httpClient.post(
      this.COMMISSION_REST_API_SERVER + '/approve-account-commission-groups',
      accountCommissionGroups,
      {
        headers: this.reqHeader,
      }
    );
  }

  // From Commissions
  public getAccountCommissionGroupByRange(
    commissionAccountNumber: string,
    fromDate: string,
    toDate: string
  ) {
    return this.httpClient.get<AccountCommissionGrouping>(
      this.COMMISSION_REST_API_SERVER +
        '/get-account-commission-group-by-range/' +
        commissionAccountNumber +
        '/' +
        fromDate +
        '/' +
        toDate,
      {
        headers: this.reqHeader,
      }
    );
  }

  // From platform AccountCommissionGrouping
  public getApprovedCommissionGroup() {
    return this.httpClient.get<AccountCommissionGrouping[]>(
      `${this.COMMISSION_REST_API_SERVER}/platform-account-commission-group`,
      {
        headers: this.reqHeader,
      }
    );
  }

  public getPaidCommissionGroupByRange(fromDate: number, toDate: number) {
    return this.httpClient.get<AccountCommissionGrouping[]>(
      `${this.COMMISSION_REST_API_SERVER}/paid-account-commission-group-by-range/${fromDate}/${toDate}`,
      {
        headers: this.reqHeader,
      }
    );
  }

  public getPaidCommissionGroupById(id: string) {
    return this.httpClient.get<AccountCommissionGrouping>(
      `${this.COMMISSION_REST_API_SERVER}/paid-account-commission-group/${id}`
    );
  }

  public payAccountCommissionGroup(
    accountCommissionGroups: Array<AccountCommissionGrouping>
  ) {
    return this.httpClient.post<Array<AccountCommissionGrouping>>(
      `${this.COMMISSION_REST_API_SERVER}/pay-account-commission-groups`,
      accountCommissionGroups,
      {
        headers: this.reqHeader,
      }
    );
  }

  public getUnpaidCommissions(
    fromDate: number,
    toDate: number
  ): Observable<Array<AccountCommissionGrouping>> {
    return this.httpClient.get<Array<AccountCommissionGrouping>>(
      this.COMMISSION_REST_API_SERVER +
        '/unpaid-by-account/' +
        fromDate +
        '/' +
        toDate,
      {
        headers: this.reqHeader,
      }
    );
  }
}
