import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Route, Router, Routes } from '@angular/router';
import { PlatformUser } from 'business-logic';
import { FirebaseApp, initializeApp } from 'firebase/app';
import {
  Auth,
  GoogleAuthProvider,
  User,
  getAuth,
  signInWithPopup,
} from 'firebase/auth';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

// ------------------------------------------------------------------------------------------------
// providedIn: root makes this a global singleton instance of the PortalUserService.
// ------------------------------------------------------------------------------------------------
@Injectable({
  providedIn: 'root',
})
export class PortalUserService {
  private firebaseApp: FirebaseApp | undefined;
  private firebaseAuth: Auth | undefined;
  private portalUser: PlatformUser | undefined;
  private portalUser$: BehaviorSubject<PlatformUser | undefined>;
  private platformUserApiUrl = '/api/1/platformUser';
  private permittedExternalEmailAddresses: string[] = [
    'alink2me@gmail.com',
    'erin.marie.prkns@gmail.com',
    'robsaylor@gmail.com',
  ];

  private _portalRoutes: Routes | undefined;

  constructor(
    private http: HttpClient,
    private router: Router,
  ) {
    this.portalUser$ = new BehaviorSubject<PlatformUser | undefined>(
      this.portalUser,
    );
  }

  initializeFirebase(firebaseConfig?: any): Promise<void> {
    return new Promise((resolve, reject) => {
      // console.log('PortalUserService::initializeFirebase() - Enter');

      if (firebaseConfig) {
        // console.log(' - Initializing Firebase for DEVELOPMENT');
        this.firebaseApp = initializeApp(firebaseConfig);
      } else {
        // console.log(' - Initializing Firebase for PRODUCTION');
        this.firebaseApp = initializeApp();
      }

      // console.log(' - Initializing Firebase Auth');
      this.firebaseAuth = getAuth(this.firebaseApp);
      // console.log('firebaseAuth', this.firebaseAuth);

      this.firebaseAuth.onAuthStateChanged((user) => {
        // console.log('PortalUserService --> Auth.onAuthStateChanged() - Enter');
        console.log(
          '%c * AUTH USER: ' + user?.email + ' * ',
          'background: #11a3ff; color: white',
        );

        if (user) {
          this.setPortalUserFromFirebaseUser(user, 'onAuthStateChanged').then(
            () => resolve(),
          );
        } else {
          this.clearPortalUser();
          resolve();
        }
        // console.log('PortalUserService --> Auth.onAuthStateChanged() - Exit');
      });
      // console.log('PortalUserService::initializeFirebase() - Exit');
    });
  }

  // ----------------------------------------------------------------------------------------------
  // Return the current portal user or, if there is none, return undefined.
  // ----------------------------------------------------------------------------------------------
  // getCurrentUser(): PortalUser | undefined {
  // console.log('PortalUserService::getCurrentUser() - Enter');
  //   return this.portalUser;
  // }

  getPortalUser$(): BehaviorSubject<PlatformUser | undefined> {
    // console.log('PortalUserService::getPortalUser$() - Enter');
    return this.portalUser$;
  }

  get portalRoutes() {
    return this._portalRoutes;
  }

  set portalRoutes(value: Routes | undefined) {
    this._portalRoutes = value;
  }

  private getRouteFromPath(routePath: string): Route | undefined {
    // Remove leading and trailing slashes.
    routePath = routePath.trim().replace(/^\/|\/$/g, '');

    // Find the matching route.
    return this._portalRoutes?.find((route) => route.path === routePath);
  }

  private getGuardRolesForRoutePath(routePath: string) {
    let route: Route | undefined = this.getRouteFromPath(routePath);

    let guardRoles: string[] | undefined = route?.data?.guardRoles;

    return guardRoles;
  }

  hasPermissionToRoute(route: string): boolean {
    // Get the guard roles for the route.
    const guardRoles = this.getGuardRolesForRoutePath(route);

    // If there are no guard roles, then the user has permission.
    if (guardRoles === undefined) {
      return true;
    }

    // If the user is not logged in or does not have any portals, then the user does not have permission.
    if (
      this.portalUser === undefined ||
      this.portalUser.portals === undefined ||
      this.portalUser.portals.length === 0
    ) {
      return false;
    }

    // Check if the user has any of the required guard roles.
    return guardRoles.some((role) => this.portalUser?.portals?.includes(role));
  }

  // ----------------------------------------------------------------------------------------------
  // Sign in with Google Authentication Provider provided by Firebase.
  // The only way to sign in to a JFW portal is through the Google Authentication Provider using
  //   JIMSFW.COM credentials.
  // ----------------------------------------------------------------------------------------------
  signInWithPopup(): void {
    if (this.firebaseAuth) {
      let googleAuthProvider: GoogleAuthProvider = new GoogleAuthProvider();
      googleAuthProvider.addScope('profile');
      googleAuthProvider.addScope('email');
      signInWithPopup(this.firebaseAuth, googleAuthProvider)
        .then(async (result) => {
          await this.setPortalUserFromFirebaseUser(
            result.user,
            'signWithPopup',
          );
          this.router.navigate(['/home']);
        })
        .catch((error) => {
          // console.error('Error returned from SignInWithPopup()');
          // console.error(error.code, error.message);
          // console.error(error.email);
          this.clearPortalUser();
        });
    } else {
      // console.error(
      //   'Unable to sign in because Firebase Auth has not been properly initialized.'
      // );
      // console.error(
      //   'Ensure that the portal application calls BasePortalComponent::ngOnInit()'
      // );
    }
  }

  // ----------------------------------------------------------------------------------------------
  // Either the user has clicked LOGOUT or the authenticated user is not authorized to access
  //   this portal.
  // Sign out of Firebase and clear the current user.
  // ----------------------------------------------------------------------------------------------
  signOut(): void {
    // console.log('PortalUserService::signOut() - Enter');
    if (this.firebaseAuth) {
      // console.log('firebaseAuth.signOut()', this.firebaseAuth);
      this.firebaseAuth.signOut();
    }
    this.clearPortalUser();
    this.router.navigate(['/']).then(() => {
      window.location.reload();
    });
    // console.log('PortalUserService::signOut() - Exit');
  }

  // ----------------------------------------------------------------------------------------------
  // A Firebase Auth user has been authenticated.
  // Check to ensure the credential has an email address and that it ends in JIMSFW.COM.
  // If so, set the current portal user based on the properties of the Firebase user.
  // If not, sign out of Firebase and clear the current user.
  // ----------------------------------------------------------------------------------------------
  private async setPortalUserFromFirebaseUser(
    fbUser: User | null,
    caller: string,
  ) {
    //return new Promise(async (resolve, reject) => {
    console.log('%c-> setPortalUserFromFirebaseUser()', 'font-weight:bold');
    console.log('-- Called by: ' + caller);
    if (
      fbUser &&
      fbUser.email &&
      (fbUser.email.toUpperCase().endsWith('JIMSFW.COM') ||
        this.isExternalEmailAddressPermitted(fbUser.email))
    ) {
      // ------------------------------------------------------------------------------------------
      // If the currently-authenticated user is a JIMSFW.COM user, load the current portal user
      // from properties of the Firebase user.
      // ------------------------------------------------------------------------------------------
      this.portalUser = {
        firebaseAuthenticationUserId: fbUser.uid,
        firestoreDocumentId: '',
        firestoreToken: '',
        displayName: fbUser.displayName ?? '',
        photoUrl: fbUser.photoURL,
        portals: undefined,
        modules: undefined,
      };

      // ------------------------------------------------------------------------------------------
      // With the currently-authenticated user loaded, get the user's JWT token needed for
      // calling the JFW API.
      // ------------------------------------------------------------------------------------------

      const newToken = await fbUser.getIdToken();

      this.portalUser!.firestoreToken = newToken;
      this.portalUser$.next(this.portalUser);

      // --------------------------------------------------------------------------------------
      // With the token, call the PortalUser API to retrieve the current user's permissions.
      // --------------------------------------------------------------------------------------
      const getter = await this.getCurrentPortalUserPermissions();

      return;
    } else {
      // ------------------------------------------------------------------------------------------
      // If the currently-authenticated user can not be verified as a JIMSFW.COM user, immediately
      // sign the user out.
      // This is poorly handled, some feedback should be provided to the user.
      // ------------------------------------------------------------------------------------------
      // console.log('************************************************');
      // console.log('* Authenticated user is not a JIMSFW.COM user! *');
      // console.log('************************************************');
      this.signOut();
      return;
    }
  }

  private async getPlatformUserPermissions(): Promise<any> {
    if (this.portalUser === undefined) {
      return;
    }

    try {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.portalUser.firestoreToken,
        }),
      };

      const source$ = this.http.get<any>(
        `${this.platformUserApiUrl}/platformuser`,
        {
          headers: httpOptions.headers,
        },
      );

      return await firstValueFrom(source$);
    } catch (error) {
      console.log('-- Error Finding PlatformUser');
      // console.log(error);
      return undefined;
    }
  }

  private async getCurrentPortalUserPermissions() {
    console.log('%c-> getCurrentPortalUserPermissions() ', 'font-weight:bold');

    if (this.portalUser && this.portalUser.firestoreToken) {
      const apiResponse = await this.getPlatformUserPermissions();

      if (apiResponse === undefined) {
        console.log('-- Portal user not defined');
        return;
      }

      console.log('-- getPlatformUserPermissions apiResponse', apiResponse);

      console.log(' -- Portal/Platform user found.');
      // console.log(" -- API Response: ", apiResponse)
      this.portalUser!.firestoreDocumentId = apiResponse.firestoreDocumentId;
      this.portalUser!.portals = apiResponse.portals ?? [];
      this.portalUser!.modules = apiResponse.modules ?? [];
      console.log(' -- PlatformUser permissions retrieved.');
      console.log(' -- PlatformUser portal roles: ', this.portalUser?.portals);
      // this.portalUser$.next(this.portalUser);
    }

    return;
  }

  // ----------------------------------------------------------------------------------------------
  // Helper function to clear the current portal user and to notify all subscribers to the
  //  portalUser$ observable.
  // ----------------------------------------------------------------------------------------------
  private clearPortalUser(): void {
    this.portalUser = undefined;
    this.portalUser$.next(this.portalUser);
  }

  // ----------------------------------------------------------------------------------------------
  // Temporary Function to Check if Non-Jims Email is Allowed
  // ----------------------------------------------------------------------------------------------
  private isExternalEmailAddressPermitted(checkEmail: string) {
    let allowed: boolean = false;

    this.permittedExternalEmailAddresses.forEach((email) => {
      if (checkEmail.toLowerCase() === email.toLowerCase()) {
        allowed = true;
      }
    });

    return allowed;
  }
}
