import { HttpContext, HttpContextToken } from '@angular/common/http';

type AppCheckRequiredNoForceRefresh = { required: true; forceRefresh: false };
type AppCheckRequiredForceRefresh = { required: true; forceRefresh: true };
type AppCheckNotRequired = { required: false; forceRefresh: false };

type RequireAppCheck =
  | AppCheckRequiredNoForceRefresh
  | AppCheckRequiredForceRefresh
  | AppCheckNotRequired;

/**
 * HttpContextToken used to mark an http request to include the AppCheck token.  You should not need to use this token directly.
 * Instead, use {@link requireAppCheck}.
 *
 * See our full [App Check Documentation](../../../../../documentation/app-check.md) for more details.
 */
export const REQUIRE_APP_CHECK = new HttpContextToken<RequireAppCheck>(() => ({
  required: false,
  forceRefresh: false,
}));

/**
 * Use to mark an http request to include the AppCheck token.
 *
 * There is an AppCheckInterceptor in app-check.interceptor.ts, which looks for a {@link REQUIRE_APP_CHECK} HttpContextToken. If this token is set to true on the request, then the interceptor will attach the AppCheck token to the headers. It will first check for a token in cache, but if the token has expired it will get a new token (force refresh set to true).
 * To “mark” an http request as needing an AppCheck token, do the following:
 * 1.  Import requireAppCheck (note: this is NOT the HttpContextToken mentioned above).
 *
 * 2.  Include requireAppCheck as part of the options object (2nd argument for GET requests, 3rd argument for POST requests). The requireAppCheck object is just a const that sets REQUIRE_APP_CHECK HttpContextToken to true.
 *
 * 3.  That’s it. By doing it this way we are not requesting more AppCheck tokens than is necessary.
 *
 * @example
 * import { requireAppCheck } from '@jfw-library/shared/app-check';

 * // With no other options in the options object
 * this.httpClient.get<UserEcomSettings>(apiUrl, requireAppCheck);
 *
 * // With additional options in the options object
 * this.httpClient.get<UserEcomSettings>(apiUrl, {
 *  ...requireAppCheck,
 *  params: {someParam: 'some value'}
 * });
 *
 * See our full [App Check Documentation](../../../../../documentation/app-check.md) for more details.
 */
export const requireAppCheck = {
  context: new HttpContext().set(REQUIRE_APP_CHECK, {
    required: true,
    forceRefresh: false,
  }),
} as const;

/**
 * Use to mark an http request to include the AppCheck token and force refresh the token.  This is useful for testing.
 * Note: forceRefresh does not work entirely as expected.  It seems that once forceRefreshed is called with a true value,
 * it continues to force refresh the token on every request even after you pass forceRefresh=false.
 * See our full [App Check Documentation](../../../../../documentation/app-check.md) for more details.
 */
export const requireAppCheckForceRefresh = {
  context: new HttpContext().set(REQUIRE_APP_CHECK, {
    required: true,
    forceRefresh: true,
  }),
} as const;
