import { Component, OnDestroy, OnInit } from '@angular/core';
import { PlatformUser } from 'business-logic';
import { BehaviorSubject, Subscription } from 'rxjs';
import { PortalUserService } from '../../services/portal-user/portal-user.service';

@Component({
  selector: 'jfw-portal-sign-in',
  templateUrl: './portal-sign-in.component.html',
  styleUrls: ['./portal-sign-in.component.scss'],
})
export class PortalSignInComponent implements OnInit, OnDestroy {
  portalUser$: BehaviorSubject<PlatformUser | undefined>;
  portalUser: PlatformUser | undefined;
  subscription = new Subscription();

  constructor(private portalUserService: PortalUserService) {
    this.portalUser$ = this.portalUserService.getPortalUser$();
  }

  ngOnInit(): void {
    this.subscription.add(
      this.portalUser$.subscribe({
        next: (portalUser) => (this.portalUser = portalUser),
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onClickSignInButton(): void {
    this.portalUserService.signInWithPopup();
  }

  onClickSignOutButton(): void {
    this.portalUserService.signOut();
  }
}
