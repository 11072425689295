import { AbstractControl, ValidatorFn } from '@angular/forms';

export class JfwEmailValidator {
  static isValid() {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const email = control.value;

      if (email === '') {
        return null;
      }

      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

      // Check if the password length is at least 10 characters.
      if (!email || !email.match(emailPattern)) {
        return { invalidEmail: true };
      }

      return null;
    };
  }
}
