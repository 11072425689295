<div class="flex-container">
  <div class="flex-item">
    <p>Commission amounts have been submitted for processing</p>
    <button
      class="home-page-button"
      mat-flat-button
      color="primary"
      routerLink="/"
    >
      BACK TO HOME PAGE
    </button>
    <button
      class="home-page-button"
      mat-flat-button
      color="primary"
      routerLink="/commission/group/paid"
    >
      Review Paid Group Commissions
    </button>
  </div>
</div>



