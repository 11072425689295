<div class="container">
  <div>
    <button mat-flat-button (click)="goBack()">
      <span><mat-icon class="prev">chevron_left</mat-icon>PREV</span>
    </button>
    <h1>Search Commissions</h1>
  </div>
  <div class="form-container">
    <form [formGroup]="searchForm" (ngSubmit)="onSubmit()">
      <mat-form-field appearance="fill">
        <mat-label>Event Date</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input
            matStartDate
            formControlName="dateStart"
            placeholder="Start Date"
          />
          <input matEndDate formControlName="dateEnd" placeholder="End Date" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
      <button type="submit" mat-icon-button><mat-icon>search</mat-icon></button>
    </form>
    <mat-form-field appearance="outline">
      <mat-label>Filter Results</mat-label>
      <input
        matInput
        [ngModel]="filterText"
        (ngModelChange)="onFilterChange($event)"
      />
    </mat-form-field>
  </div>
  <div class="commissions" *ngIf="this.commissions.filteredData.length !== 0">
    <table
      matSort
      mat-table
      [dataSource]="this.commissions"
      class="mat-elevation-z8"
      (matSortChange)="sortData($event)"
    >
      <ng-container matColumnDef="commissionAccountNumber">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header="commissionAccountNumber"
        >
          Commission Account Number
        </th>
        <td mat-cell *matCellDef="let commission">
          {{ commission.commissionAccountNumber }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="productionWeek">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="productionWeek">
          P-Week
        </th>
        <td mat-cell *matCellDef="let commission">
          {{ commission.productionWeek }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="eventDescription">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header="eventDescription"
        >
          Event Description
        </th>
        <td mat-cell *matCellDef="let commission">
          {{ commission.eventDescription }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="customer.firstName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="firstName">
          First Name
        </th>
        <td mat-cell *matCellDef="let commission">
          {{ commission.customer.firstName }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="customer.lastName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="lastName">
          Last Name
        </th>
        <td mat-cell *matCellDef="let commission">
          {{ commission.customer.lastName }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="accountNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="accountNumber">
          Account Number
        </th>
        <td mat-cell *matCellDef="let commission">
          {{ commission.accountNumber }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="orderNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="orderNumber">
          Order Number
        </th>
        <td mat-cell *matCellDef="let commission">
          {{ commission.orderNumber }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="orderStatus">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="orderStatus">
          Order Status
        </th>
        <td mat-cell *matCellDef="let commission">
          {{ commission.orderStatus }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="commissionType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="commissionType">
          Commission Type
        </th>
        <td mat-cell *matCellDef="let commission">
          {{ commission.commissionType }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="commissionStatus">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header="commissionStatus"
        >
          Commission Status
        </th>
        <td mat-cell *matCellDef="let commission">
          {{ commission.commissionStatus }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="rentalCommission">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header="rentalCommission"
        >
          Rental Commission
        </th>
        <td mat-cell *matCellDef="let commission">
          {{ commission.rentalCommission }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="purchaseCommission">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header="purchaseCommission"
        >
          Purchase Commission
        </th>
        <td mat-cell *matCellDef="let commission">
          {{ commission.purchaseCommission }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="totalCommission">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="totalCommission">
          Total Commission
        </th>
        <td mat-cell *matCellDef="let commission">
          {{ commission.totalCommission }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="details">
        <th mat-header-cell *matHeaderCellDef>Details</th>
        <td mat-cell *matCellDef="let commission">
          <a routerLink="/commission/details/{{ commission.orderId }}">
            <mat-icon>remove_red_eye</mat-icon>
          </a>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
    </table>
  </div>
</div>
