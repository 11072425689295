<div class="container" *ngIf="!showSpinner">
  <div>
    <button mat-flat-button (click)="goBack()">
      <span><mat-icon class="prev">chevron_left</mat-icon>PREV</span>
    </button>
    <h1>Review Paid Group Commissions</h1>
  </div>

  <div class="form-container">
    <form [formGroup]="searchForm" (ngSubmit)="onSubmit()">
      <mat-form-field appearance="fill">
        <mat-label>Paid Date</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input
            matStartDate
            formControlName="dateStart"
            placeholder="Start Date"
          />
          <input matEndDate formControlName="dateEnd" placeholder="End Date" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
      <button type="submit" mat-icon-button><mat-icon>search</mat-icon></button>
    </form>
  </div>

  <div class="commissions" *ngIf="this.accountCommissionGroups !== undefined">
    <table
      mat-table
      [dataSource]="this.accountCommissionGroups"
      class="mat-elevation-z8"
    >
      <ng-container matColumnDef="commissionAccountNumber">
        <th mat-header-cell *matHeaderCellDef>Commission Account Number</th>
        <td mat-cell *matCellDef="let commission">
          {{ commission.commissionAccountNumber }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="approvedOn">
        <th mat-header-cell *matHeaderCellDef>Approved Date</th>
        <td mat-cell *matCellDef="let commission">
          {{ commission.approvedOn * 1000 | date : "shortDate" }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="approvedBy">
        <th mat-header-cell *matHeaderCellDef>Approved By</th>
        <td mat-cell *matCellDef="let commission">
          {{ commission.approvedBy }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="timePeriod">
        <th mat-header-cell *matHeaderCellDef>Time Period</th>
        <td mat-cell *matCellDef="let commission">
          {{ commission.approvedFrom * 1000 | date : "shortDate" }} to
          {{ commission.approvedTo * 1000 | date : "shortDate" }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="totalCommission">
        <th mat-header-cell *matHeaderCellDef>Paid Commission</th>
        <td mat-cell *matCellDef="let commission">
          {{ commission.paidCommission | currency }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="process">
        <th mat-header-cell *matHeaderCellDef>View</th>
        <td mat-cell *matCellDef="let commission; let i = index">
          <a routerLink="/commission/group/statement/{{ commission.id }}">
            <mat-icon>remove_red_eye</mat-icon>
          </a>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
    </table>
  </div>
</div>

<mat-spinner *ngIf="showSpinner"></mat-spinner>
