<mat-form-field
  [appearance]="appearance"
  [ngStyle]="{ 'text-align': textAlign }"
>
  <mat-label>{{ label }}</mat-label>
  <input
    matInput
    [formControl]="form.controls.phone"
    mask="(000) 000-0000"
    [required]="required"
    data-cy="phone"
    (focus)="onInputFocus()"
  />
  <mat-error>Format: (555) 555-5555</mat-error>
</mat-form-field>
