import { Inject, Injectable } from '@angular/core';
import { SharedCommunicationQueueService } from '@jfw-library/shared/services';
import { CommunicationQueue, Event, RoleEnum, Site } from 'common-types';
import { from } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PlatformCommunicationQueueService {
  constructor(
    @Inject('environment') private environment: any,
    private sharedCommQService: SharedCommunicationQueueService,
  ) {}

  ///////////////////////////////////////////////////////////////////////////
  //  SHARED METHODS                                                       //
  ///////////////////////////////////////////////////////////////////////////

  private sendToCommunicationQueue(
    queueData: Partial<CommunicationQueue>,
  ): Promise<Partial<CommunicationQueue>> {
    return this.sharedCommQService.sendToCommunicationQueue(queueData);
  }

  public async queueEmailMemberInvite(
    event: Event,
    eventMemberEmail: string,
    memberFirstName: string | undefined,
    memberLastName: string | undefined,
    isOrganizer: boolean = false,
  ): Promise<Partial<CommunicationQueue>> {
    return this.sharedCommQService.queueEmailMemberInvite(
      event,
      eventMemberEmail,
      memberFirstName,
      memberLastName,
      isOrganizer,
    );
  }

  public async queueEmailCoOwnerInvite(
    event: Event,
    eventMemberEmail: string,
    coOwnerFirstName: string | undefined,
    coOwnerLastName: string | undefined,
    coOwnerPhone: string | undefined,
  ): Promise<Partial<CommunicationQueue>> {
    return this.sharedCommQService.queueEmailCoOwnerInvite(
      event,
      eventMemberEmail,
      coOwnerFirstName,
      coOwnerLastName,
      coOwnerPhone,
    );
  }

  public queueEmailDealerInvite(
    origin: Site,
    firstName: string,
    lastName: string,
    email: string,
    documentId?: string,
    displayName?: string,
    userId?: string,
  ) {
    return this.sharedCommQService.queueEmailDealerInvite(
      origin,
      firstName,
      lastName,
      email,
      documentId,
      displayName,
      userId,
    );
  }

  ///////////////////////////////////////////////////////////////////////////
  //  PLATFORM-ONLY METHODS                                                //
  ///////////////////////////////////////////////////////////////////////////

  // platform
  public queueEmailPlatformUserInvite(
    origin: Site,
    firstName: string,
    lastName: string,
    email: string,
    roles: Array<RoleEnum>,
    documentId?: string,
  ) {
    let inviteUrl: string = `${this.environment.platform_user_prefix_for_email_and_sms_communication}/register/${documentId}/${email}`;

    let queueData: Partial<CommunicationQueue> = {
      recipient: email,
      communicationMethod: 'email',
      messageType: 'platform-user-invite',
      origin: origin,
      templateData: {
        platformUser: {
          firstName: firstName,
          lastName: lastName,
          email: email,
          documentId: documentId,
          roles: roles,
        },
        inviteUrl: inviteUrl,
      },
    };

    console.log(inviteUrl);

    return from(this.sendToCommunicationQueue(queueData));
  }
}
