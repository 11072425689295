import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Commission } from 'common-types';
import { first, Subscription } from 'rxjs';
import { CommissionService } from '../../../services/commission/commission.service';

@Component({
  selector: 'jfw-commission-details',
  templateUrl: './commission-details.component.html',
  styleUrls: ['./commission-details.component.scss'],
})
export class CommissionDetailsComponent implements OnInit, OnDestroy {
  showSpinner = true;
  orderId: string | undefined;
  commission: Commission | undefined;
  displayedColumns: string[] = [
    'styleType',
    'legacyStyleCode',
    'styleGroup',
    'warehouse',
    'lineStatus',
    'commission',
    'replacement',
  ];

  subscription = new Subscription();
  constructor(
    private commissionService: CommissionService,
    private route: ActivatedRoute,
    private location: Location,
  ) {}

  ngOnInit(): void {
    this.orderId = this.route.snapshot.params.orderId;

    if (this.orderId) {
      this.subscription.add(
        this.commissionService
          .getCommissionByOrderId(this.orderId)
          .pipe(first())
          .subscribe({
            next: (data) => {
              this.commission = data;
              this.showSpinner = false;
              console.log('commission', this.commission);
              if (!this.commission) {
                alert(`No commission exists with order id: ${this.orderId}.`);
              }
            },
            error: (e) => {
              console.log(e);
            },
          }),
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  goBack() {
    this.location.back();
  }
}
