import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { PortalFeatureModuleGroup } from 'common-types';
import { FeatureModuleService } from '../../services/feature-module/feature-module.service';
import { PortalUserService } from '../../services/portal-user/portal-user.service';

@Component({
  selector: 'jfw-portal-navigation',
  templateUrl: './portal-navigation.component.html',
  styleUrls: ['./portal-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortalNavigationComponent implements OnInit {
  featureModuleGroups: PortalFeatureModuleGroup[] = [];

  constructor(
    private featureModuleService: FeatureModuleService,
    private portalUserService: PortalUserService
  ) {}

  ngOnInit(): void {
    this.featureModuleGroups = this.featureModuleService.featureModuleGroups;
  }

  hasPermissionToRoute(route: string) {
    return this.portalUserService.hasPermissionToRoute(route);
  }
}
