import { Injectable } from '@angular/core';
import { PortalFeatureModuleGroup } from 'common-types';

@Injectable({
  providedIn: 'root',
})
export class FeatureModuleService {
  private _featureModuleGroups: PortalFeatureModuleGroup[] = [];

  constructor() {}

  get featureModuleGroups() {
    return this._featureModuleGroups;
  }

  set featureModuleGroups(value: PortalFeatureModuleGroup[]) {
    this._featureModuleGroups = value;
  }
}
