<div class="header">
  <h1>Account List</h1>
</div>

<ng-container *ngIf="accounts$ | async as accounts">
  <ng-container *ngIf="accounts !== undefined && accounts.length > 0">
    <table
      #tableAccounts
      mat-table
      [dataSource]="accounts"
      class="mat-elevation-z8"
      style="max-width: 1000px"
    >
      <ng-container matColumnDef="accountNumber">
        <th mat-header-cell *matHeaderCellDef class="table-col">
          Account Number
        </th>
        <td mat-cell *matCellDef="let account" class="table-col">
          {{ account.accountNumber }}
        </td>
      </ng-container>

      <ng-container matColumnDef="accountName">
        <th mat-header-cell *matHeaderCellDef class="table-col">
          Account Name
        </th>
        <td mat-cell *matCellDef="let account" class="table-col">
          {{ account.accountName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="viewEdit">
        <th mat-header-cell *matHeaderCellDef class="table-col">View</th>
        <td mat-cell *matCellDef="let account" class="table-col">
          <button
            mat-raised-button
            [routerLink]="['/account/details', account.accountNumber]"
          >
            View/Edit
          </button>
        </td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="displayedColumns"
        style="display: flex"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        style="display: flex"
      ></tr>
    </table>

    <div
      *ngIf="!loading && !noMoreAccounts"
      (click)="loadDocuments()"
      class="button-more"
    >
      <mat-icon class="button-more-icon">expand_more</mat-icon>
    </div>

    <div *ngIf="loading">
      <mat-spinner></mat-spinner>
    </div>
  </ng-container>
</ng-container>
<div #tableTop id="sticky" style="border: 1px solid black"></div>

<!-- <br /><br />
<textarea #newAccountData matInput rows="10" style="width: 500px"></textarea>
<p>
  <button
    mat-stroked-button
    color="primary"
    (click)="insertAccount(newAccountData.value)"
  >
    Add
  </button>
</p> -->

<div *ngIf="loading">
  <mat-spinner></mat-spinner>
</div>

<!-- <div style="min-height: 50px"></div>
<button *ngIf="!loading && !noMoreAccounts" (click)="loadDocuments()">
  Load More
</button> -->
