import { ModuleWithProviders, NgModule } from '@angular/core';
import { AccountService } from './services/account/account.service';
import { PlatformCommunicationQueueService } from './services/communication-queue/communication-queue.service';
import { DealerUserService } from './services/dealer-user/dealer-user.service';

@NgModule()
export class PlatformServicesModule {
  public static forRoot(
    environment: any
  ): ModuleWithProviders<PlatformServicesModule> {
    return {
      ngModule: PlatformServicesModule,
      providers: [
        AccountService,
        DealerUserService,
        PlatformCommunicationQueueService,
        {
          provide: 'environment',
          useValue: environment,
        },
      ],
    };
  }
}
