import { InjectionToken } from '@angular/core';
import { PlatformPortalEnvironment } from 'common-types';

/**
 * This is the environment.ts file for the portal.
 * It has a type of PortalEnvironment, but when injected in the portal's app.module, it is an object literal with the portal-specific type.
 */
export const PORTAL_ENVIRONMENT = new InjectionToken<PlatformPortalEnvironment>(
  'Portal environment.ts file'
);
